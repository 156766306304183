
const Test = ()=>{
    return (
        <>
        <div className="container mx-auto p-8 text-center text-2xl">
            Coming Soon!
        </div>
        </>
    );
};

export default Test;